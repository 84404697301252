import React from "react";
// import './App.css';

// import { useEffect } from 'react';
// import Logo from '../components/AshLogo';
// import { init, animate } from '../utils/logo.js';

function Referral() {
  // useEffect(() => {
  //   init();
  //   animate();
  // }, []);

  return (
    <main>
      {/* <Logo class="absolute z-0" /> */}
      <div class="relative z-10">
        <div class="container mx-auto grid grid-cols-2 gap-8 px-8 md:px-12">
          <div class="center-left col-span-2 lg:col-span-1">
            <div class="xl:max-w-screen-sm">
              <h1 class="text-8xl">
                Earn up
                <wbr /> to 4%
                <wbr /> each month
                <wbr /> on referrals!
              </h1>
              <p class="max-w-screen-md font-sans font-normal text-gray-200 ">
                Addresses that are referred get 1% back on their first
                transaction.
              </p>
            </div>
          </div>

          <div class="ui col-span-1 flex flex-wrap content-center justify-center rounded-2xl py-8">
            <div class="w-full rounded-3xl px-5 py-2 sm:px-8 lg:px-12">
              <label class="block w-full">
                <div class="mt-3 text-xl text-gray-100">
                  Referral Email Address
                </div>
                <input
                  type="email"
                  class="
                                     text-md mt-1 block w-full rounded-md border-transparent bg-gray-100
                                     py-1
                                     shadow-2xl
                                     focus:border-gray-500
                                     focus:bg-white focus:ring-0
                                     md:mt-1
                                     md:py-2 md:text-xl lg:py-4"
                  placeholder="you@example.com"
                />
              </label>
              <label class="block w-full pt-3">
                <div class="mt-3 text-xl text-gray-100">
                  New Buyer Email Address
                </div>
                <input
                  type="email"
                  class="
                                          text-md mt-1 block w-full rounded-md border-transparent bg-gray-100
                                          py-1
                                          focus:border-green-500
                                          focus:bg-white
                                          focus:ring-0
                                          md:mt-1
                                          md:py-2 md:text-xl lg:py-4
                                    "
                  placeholder="friend@example.com"
                />
              </label>
              <label class="mt-4 inline-flex items-center">
                <input
                  type="checkbox"
                  class="
                                                                                rounded
                                                                                border-transparent
                                                                                bg-gray-200
                                                                                text-gray-700 focus:border-transparent
                                                                                focus:bg-gray-200
                                                                                focus:ring-1 focus:ring-gray-500 focus:ring-offset-2
                                                                              "
                />
                <span class="ml-2">
                  I agree to receive other communications from Ash Token.
                </span>
              </label>
              <p class="mt-0 pt-2 text-center">
                <div class="">
                  <a
                    href="#"
                    class="btn my-3 block w-full rounded-lg py-3
                               text-2xl font-bold shadow-2xl drop-shadow-2xl md:py-5
                               "
                  >
                    Submit
                  </a>
                </div>
              </p>
              {/* <div class="pt-12 text-xs">
               <p class="pb-1">Ash Token is committed to protecting and respecting your privacy, and we’ll only use your personal information to
                 administer your account and to provide the products and services you requested from us. From time to time, we would like
                 to contact you about our products and services, as well as other content that may be of interest to you. If you consent
                 to us contacting you for this purpose, please tick above to say how you would like us to contact you</p>

               <p class="pb-1">You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy
                 practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy.
                 By clicking submit below, you consent to allow Ash Token to store and process the personal information submitted
                 above to provide you the content requested.</p>
             </div> */}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Referral;
