import axios from "axios";
import React from "react";

const baseURL =
  "https://api.coingecko.com/api/v3/simple/price?ids=ash-token&vs_currencies=usd";

export default function CurrentPrice() {
  const [post, setPost] = React.useState(null);

  React.useEffect(() => {
    axios.get(baseURL).then((response) => {
      setPost(response.data["ash-token"]);
      // console.log(response.data["ash-token"]);
      // var hello = response.data["ash-token"];
    });
  }, []);

  const stylePrice = {
    letterSpacing: "1.5px",
  };
  const styleLabel = {
    textShadow: "0",
  };

  if (!post) return null;

  return (
    <div class="mb-6 lg:mb-0">
      <div class="bg-gradient-to-r from-violet-500 via-blue-400 to-green-400 bg-clip-text text-2xl font-extrabold text-transparent md:text-5xl">
        ${post.usd}
      </div>
      <div class="text-sm font-bold uppercase">Per Unit of Voting Power</div>
      <div>
        Like us on{" "}
        <a
          class="underline"
          href="https://www.coingecko.com/en/coins/ash-token"
          target="_blank"
          rel="noopener noreferrer"
        >
          CoinGecko
        </a>
      </div>
    </div>
  );
}
