import React, { useEffect } from "react";
import MeetTheTeam from "./MeetTheTeam";

const staffList = [
  {
    id: 0,
    name: "Melissa Caban",
    title: "Marketing Campaign Director",
  },
  {
    id: 1,
    name: "Ben P.K. Gyepi-Garbrah II",
    title: "Ash DAO Fund Director",
  },
  {
    id: 2,
    name: "Kat Kutukeeva",
    title: "Project Coordinator",
  },
  {
    id: 3,
    name: "Scott Borland",
    title: "Web Developer",
  },
];

function Team() {
  useEffect(() => {
    document.title = "ASH Token | Meet the Team";
  }, []);

  return (
    <div>
      <MeetTheTeam />
      <div class="mb-8 mt-16">
        <h2 class="text-center">Staff</h2>
      </div>
      <div class="container mx-auto mb-24 grid grid-cols-12 gap-8 px-8 sm:gap-12 lg:pb-24">
        {staffList.map((staffList) => (
          <div class="center-center col-span-12 mb-8 flex flex-col rounded-2xl border-2 border-zinc-500 px-4 py-8 text-center sm:col-span-6 sm:mb-0 lg:col-span-3">
            <div class="text-2xl text-zinc-200">{staffList.name}</div>
            <div class="text-zinc-400">{staffList.title}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Team;
