import React from "react";
import { Link } from "react-router-dom";
import img from "../embers.png";
import imgbg from "../assets/black-felt.png";

import one from "../assets/stack/1.png";
import two from "../assets/stack/2.png";
import three from "../assets/stack/3.png";
import four from "../assets/stack/4.png";
import five from "../assets/stack/5.png";

function WhatIsAshtoken() {
  return (
    <main
      class="clear-both mt-12 content-start
   items-start py-12 md:px-8 md:py-0"
    >
      <div class="container mx-auto grid grid-cols-2 gap-12 px-4 lg:px-0">
        {/* <div class="col-span-2 mb-16 flex">

         <div class="flex-1 bg-gray-600 center-right h-0.5 my-auto"></div>
         <div class="flex-shrink-0  text-white text-center py-6 px-6 rounded-3xl border-2 border-gray-600">
           <div class=" font-bold text-ashember">Binance Smart Chain Contract</div>
           <div>0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9</div>
         </div>
         <div class="flex-1 bg-gray-600 center-right h-0.5 my-auto"></div>



     </div> */}
        <div class="center-left col-span-2 mt-12 gap-3 md:col-span-1">
          <div class="mx-auto max-w-screen-sm pb-8">
            <h2 class="">This is ASH Token </h2>
            <p class="">
              The ASH Token is a platform for funding sustainable business
              initiatives that aim to eliminate pollution from the global
              environment and drive the adoption of renewable energy sources.
            </p>
            <h2 class="hidden">How ASH Token Works</h2>
            <p class="">
              ASH is a governance token that empowers holders to decide how the
              funds raised from the token sale and transactions fees are used to
              fund environmental <br />
              start-ups.
            </p>
            <p>
              <Link
                class="btn"
                to="/pdf/AshToken-Whitepapers-2023Q4.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Whitepapers
              </Link>
            </p>
          </div>
        </div>
        <div class="item-center order-first col-span-2 flex  w-full justify-center place-self-center text-center md:col-span-1">
          <div class="m-auto w-11/12">
            {/* <iframe
              class="aspect-[4/3] w-full rounded-md shadow-2xl"
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/_8p5RNcFMYQ?color=white?autoplay=1?controls=0"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe> */}
            <iframe
              class="aspect-[16/9] h-auto w-full rounded-md shadow-2xl"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/4NUXqZlK6mw"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>

        {/* <div id="referral" class="col-span-2 md:col-span-1 center-center rounded-3xl px-12 py-8 border-4 border-ashember
       " 
       data-aos="fade-up" data-aos-mirror="true" data-aos-once="false" data-aos-anchor-placement="top-center" data-aos-delay="250" >
         <div class="text-4xl md:text-7xl font-extrabold max-w-screen-sm bg-clip-text text-transparent bg-gradient-to-br from-ashember to-ashred">
           Earn up to 4% each month on referrals!
      </div>
         <div class="text-2xl mt-4 font-bold">
           Addresses that are referred <span class="font-semibold">get 1% back</span> on their first transaction.
         </div>
         <div class="py-8 text-left w-full">
           <a href="https://dev.d340ancianf15k.amplifyapp.com" target="_blank" rel="noopener noreferrer" class="btn text-lg">Referral</a>
         </div>
         
    </div> */}

        {/* <div
          id="whatstack"
          class="relative col-span-2 clear-both hidden scale-[0.66] md:col-span-1 md:block"
        >
          <img
            src={one}
            title="smog"
            alt="smog"
            id="whatstack1"
            class="whatstack"
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-offset="200"
          />
          <img
            src={two}
            title="meeting"
            alt="meeting"
            id="whatstack2"
            class="whatstack"
            data-aos="fade-up"
            data-aos-delay="1000"
            data-aos-offset="200"
          />
          <img
            src={three}
            title="sky"
            alt="sky"
            id="whatstack3"
            class="whatstack"
            data-aos="fade-up"
            data-aos-delay="500"
            data-aos-offset="200"
          />
          <img
            src={four}
            title="coal mining"
            alt="coal"
            id="whatstack4"
            class="whatstack"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-offset="200"
          />
          <img
            src={five}
            title="coal mining"
            alt="coal"
            id="whatstack5"
            class="whatstack"
            data-aos="fade-up"
            data-aos-delay="250"
            data-aos-offset="200"
          />
        </div><!-- end image span --> */}
      </div>
      <div class="clear-both"></div>
    </main>
  );
}
export default WhatIsAshtoken;
