import React, { useEffect } from "react";

import { init, animate } from "../utils/logo.js";

function AshLogo2() {
  useEffect(() => {
    init();
    animate();
  }, []);

  return (
    // <div id="logogo" class="col-span-6 md:col-span-5 xl:col-span-6 flex center-center"></div>
    <div id="logogo" class="mx-auto"></div>
  );
}
export default AshLogo2;
