import React, { useEffect } from "react";
import "./App.css";

function Legal() {
  useEffect(() => {
    document.title = "ASH Token | Legal";
  }, []);
  return (
    <div class="max-w-2xl mx-auto p-4">
      <div
        
        class="text-white text-lg"
      >
        <div className="uppercase border-b-2 pb-2 inline-block mb-8 ">Legal</div>
        <h2 class="mt-3 text-2xl">Information Published</h2>
        <div>
          The website provides information and material of general nature. You
          are not authorized and nor should you rely on the website for legal
          advice, business advice, or advice of any kind. You act at your own
          risk in reliance on the contents of the website. Should you decide to
          act or not act you should contact a licensed attorney in the relevant
          jurisdiction in which you want or need help. In no way are the owners
          of, or contributors to, the website responsible for the actions,
          decisions, or other behavior taken or not taken by you in reliance
          upon the website.
        </div>
        <h2 class="mt-3 text-2xl">Investment Risks</h2>
        <div>
          The team does not recommend that any cryptocurrency should be bought,
          sold, or held by you. Do conduct your due diligence and consult your
          financial advisor before making any investment decisions. By
          purchasing Ash tokens, you agree that you are not purchasing a
          security or investment and you agree to hold the team harmless and not
          liable for any losses or taxes you may incur. You also agree that the
          team is presenting the token “as is” and is not required to provide
          any support or services.
        </div>
        <h2 class="mt-3 text-2xl">Compliance with Tax Obligations</h2>
        <div>
          The team does not guarantee compliance with any country across the
          world. Always make sure that you comply with your local laws and
          regulations before you make any purchase.
        </div>
        <h2 class="mt-3 text-2xl">No Warranties</h2>
        <div>
          The website is provided on an “as is” basis without any warranties of
          any kind regarding the website and/ or any content, data, materials,
          and/or services provided on the website.
        </div>
        <h2 class="mt-3 text-2xl">Limitation of Liability</h2>
        <div>
          Unless otherwise required by law, in no event shall the owners of, or
          contributors to, the Website and all of its contents, including this
          website, be liable for any damages of any kind, including, but not
          limited to, loss of use, loss of profits, or loss of data arising out
          of or in any way connected with the use of the website.
        </div>
        <h2 class="mt-3 text-2xl">Arbitration</h2>
        <div>
          The reader of the website agrees to arbitrate any dispute arising from
          or in connection with the website or this disclaimer, except for
          disputes related to copyrights, logos, trademarks, trade names, trade
          secrets, or patents.
        </div>
        <h2 class="mt-3 text-2xl">Future Statements</h2>
        <div>
          There may be matters in this website that are forward- looking
          statements. Such statements are subject to risks and uncertainty.
          Participants are cautioned not to place undue reliance on these
          forward-looking statements. The actual results or events that
          transpire afterwards may be different from what is implied here. The
          team shall not guarantee the accuracy of the forward-looking
          statements outlined in this website.
        </div>
        <div class="text-grad-red ml-0 mt-3 block text-xl md:ml-0">
          Revised: Dec 13, 2021
        </div>
      </div>
    </div>
  );
}

export default Legal;
