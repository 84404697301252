import React from "react";

import Presentation from "../components/Presentation";
import WhatIsAshtoken from "../components/WhatIsAshtoken";
import GetStartCTA from "../components/GetStartCTA";
import Logo from "../components/AshLogo";

import Welcome from "./Welcome";
import BlogSection from "./Blog/BlogSection";
import FundSection from "./FundSection";

import PrivateSale from "./PrivateSale";
import Triple from "./Triple/Triple";
import SponsorsSection from "./Sponsors/SponsorsSection";
import GrantApp from "./GrantApp";
import Announcements from "./announcements/Announcements";

function LandingPage() {
  return (
    <div id="page" class="overflow-x-hidden">
      {/* <Announcements /> */}
      <Logo />
      <Welcome />
      <Triple />
      <PrivateSale />
      <WhatIsAshtoken />
      <GrantApp />
      {/* <FundSection /> */}
      <Presentation />
      <SponsorsSection />
      <BlogSection />
      <GetStartCTA />
    </div>
  );
}

export default LandingPage;
