import React, { useEffect } from "react";
// import logo from './logo.svg';
import "./App.css";
import "./css/animate.css";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Header from "./components/headers/Header";
import LandingPage from "./components/LandingPage";
import Company from "./Company";
import Legal from "./Legal";
import SiteMap from "./SiteMap";
import Articles from "./Articles";
import Tokensale from "./components/Tokensale";
import Referral from "./components/Referral";
import Footer from "./components/footer/Footer";
import GetStarted from "./components/GetStarted";
// import Contract from './components/Contract';
// import  Donut  from './components/Donut';
import ScrollToTop from "./components/ScrollToTop";
import TeamPage from "./components/teams/TeamPage";
import Token from "./components/Token";
import Subheader from "./components/headers/Subheader";
import Header2 from "./components/headers/Header2";
import Header3 from "./components/headers/Header3";
import Blog from "./components/Blog/Blog";
import OurStory from "./components/OurStory/OurStory";
// import AnnouncementPage from "./components/announcements/AnnouncementPage";

// import { Trans, useTranslation, withTranslation } from "react-i18next";

const RedirectToExternalSite = () => {
  useEffect(() => {
    window.location.href = 'https://the.ashtoken.io/privatesale';
  }, []);
  
  return null; // This component doesn't have to render anything
};


function App() {
  // const { t, i18n } = useTranslation();

  // const changeLanguage = (lng) => {
  //   i18n.changeLanguage(lng);
  // };

  

  return (
    <Router>
      <ScrollToTop />
      <div className="App">
        {/* <Subheader /> */}
        <Header3 />
        <Routes>
          <Route path="/" exact element={<LandingPage />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/company" element={<Company />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/referral" element={<Referral />} />
          <Route path="/articles" element={<Articles />} />
          <Route path="/sitemap" element={<SiteMap />} />
          <Route path="/token" element={<Token />} />
          <Route path="/team" element={<TeamPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/ourstory" element={<OurStory />} />
          {/* <Route path="/announcements" element={<AnnouncementPage />} /> */}
          <Route path="/privatesale" element={<RedirectToExternalSite />} />
          <Route path="*" element={<LandingPage />} />
        </Routes>
        <div class="hidden md:block">
          <Footer />
          {/* backdrop-blur */}
        </div>
      </div>
    </Router>
  );
}

export default App;
