import React, { useEffect } from "react";
// import './App.css';

function GetStarted() {
  useEffect(() => {
    document.title = "ASH Token | Get Started";
  }, []);

  return (
    <div id="getstarted">
      <div class="container mx-auto my-4 grid grid-cols-12 overflow-hidden rounded-3xl px-4 pb-16 ">
        <div class="col-span-12">
          <h1 class="mb-4 text-center text-2xl md:text-5xl">Get Started</h1>
        </div>
        {/* <div class="col-span-12">
          <div class="mx-auto w-full max-w-4xl">
            <div class="aspect-h-9 aspect-w-16">
              <iframe
                src="https://www.youtube.com/embed/3dW4-lPtTuM"
                frameborder="0"
                title="getstarted"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </div>
        </div> */}

        <div class="col-span-12 mt-16 grid grid-cols-12 gap-4 border-t border-zinc-200 py-12">
          <div class="col-span-12 md:col-span-4">
            <h2 class="text-2xl font-bold">Setup Metamask</h2>
          </div>
          <div class="col-span-12 md:col-span-8">
            <p>
              Create an account at{" "}
              <a
                href="http://metamask.io"
                class="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Metamask.io
              </a>
              .
            </p>
            <p>
              Install Metamask for Chrome Webstore for any Blink Browser like
              Google Chrome, Edge, Brave, and Chromium.
            </p>
            <p>
              Write down Secret Recovery Phrase and do not lose it or you will
              lose your Ash Tokens.
            </p>
            <p>Have your reference ID number ready for next step.</p>
          </div>
        </div>
        {/*Step*/}

        <div class="col-span-12 mt-16 grid grid-cols-12 gap-4 border-t border-zinc-200 py-12">
          <div class="col-span-12 md:col-span-4">
            <h2 class="text-2xl font-bold">Setup Binance </h2>
          </div>
          <div class="col-span-12 md:col-span-8">
            <p>
              Create an account at{" "}
              <a
                href="https://accounts.binance.us/en/register?ref=35002717"
                class="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://accounts.binance.us/en/register?ref=35002717
              </a>
              .
            </p>
            <p>Set up your payment account from the Settings.</p>
            <p>Deposit funds into the account.</p>
            <p>Deposit BNB funds into BEP20 (BSC).</p>
            <p>
              Confirm your deposit, widthdraw, or transfer funds through your
              email.
            </p>
          </div>
        </div>
        {/*Step*/}

        <div class="col-span-12 mt-16 grid grid-cols-12 gap-4 border-t border-zinc-200 py-12">
          <div class="col-span-12 md:col-span-4">
            <h2 class="text-2xl font-bold">
              Purchase Ash Tokens through Pancake Swap
            </h2>
          </div>
          <div class="col-span-12 md:col-span-8">
            <p>
              Go to{" "}
              <a
                href="http://ashtoken.io"
                class="underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ashtoken.io
              </a>{" "}
              and click the <b>Buy Now</b> button.
            </p>
            <p>
              Connect your wallet through the Metamask Chrome extension and
              Metamask will set up the transfer for you.
            </p>
            <p>
              In Settings, set your <b>Slippage Tolerance</b> to at least 12%.
            </p>
            <p>Enter the amount of Ash Token you want to purchase.</p>
          </div>
        </div>
        {/*Step*/}
      </div>
    </div>
  );
}

export default GetStarted;
