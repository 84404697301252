import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import img from "../../assets/ashtoken-logo-full-color.svg";
import Nav from "../Nav";

function Header3() {
  useEffect(() => {
    window.onscroll = function () {
      scrolll();
    };

    var header = document.getElementById("header");
    var headerscroll = header.offsetTop;

    function scrolll() {
      if (window.pageYOffset > headerscroll) {
        header.classList.add("headerscroll");
      } else {
        header.classList.remove("headerscroll");
      }
    }

    var nav = document.getElementById("nav");

    nav.addEventListener("click", function () {
      document.getElementById("nav").classList.toggle("active");
      document.getElementById("header").classList.toggle("active");
      document.getElementById("mobileNav").classList.toggle("active");

      var resized = window.addEventListener("resize", resizeNav);

      function resizeNav() {
        var screenwidth = window.innerWidth;

        if (screenwidth > 768) {
          document.getElementById("nav").classList.remove("active");
          document.getElementById("header").classList.remove("active");
          document.getElementById("mobileNav").classList.remove("active");
        }
      }
    });

    var amobilelink = document
      .getElementById("mobileNav")
      .getElementsByTagName("a");
    var i;
    for (i = 0; i < amobilelink.length; i++) {
      amobilelink[i].addEventListener("click", function () {
        document.getElementById("nav").classList.remove("active");
        document.getElementById("header").classList.remove("active");
        document.getElementById("mobileNav").classList.remove("active");
      });
    }
  }, []);

  return (
    <header
      id="header"
      class="ashblue-bgcolor sticky z-50 overflow-visible px-4 transition-all duration-200 ease-in sm:px-12"
    >
      <div class="mx-auto flex py-2 md:justify-between md:py-4">
        <nav id="nav" class="mr-3.5 mt-3 flex sm:mr-2 md:hidden">
          <div class="flex h-4 w-6 flex-wrap content-between">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </nav>
        <Link id="logo" class="" to="/">
          <img
            src={img}
            alt="ASHtoken Logo"
            class="relative top-0.5 h-full max-h-10 md:top-0 md:max-h-[72px]"
          />
        </Link>
        <div class="mt-1.5 hidden md:flex">
          <ul class="flex">
            <li class="navli">
              <NavLink class="text-zinc-200" to="/get-started">
                Get Started
              </NavLink>
            </li>
            <li class="navli">
              <a
                href="https://docs.ashtoken.io/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Governance
              </a>
            </li>
            {/* <li class="navli"><NavLink class="text-zinc-200" to="/team">Team</NavLink></li> */}
            <li class="navli">
              {/* https://dev.d340ancianf15k.amplifyapp.com */}
              <a
                href="https://ashtokenemail.com/referral"
                target="_blank"
                rel="noopener noreferrer"
              >
                Referral
              </a>
            </li>
            {/* <li class="navli"><a href="/#">Articles</a></li> */}
            <li class="mr-4"></li>
            {/* <li class="btn-alt-2 center-center font-bold mt-4 relative -top-3"><a href="https://pancakeswap.finance/swap?outputCurrency=0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9" id="buyNow" class="buyNow" target="_blank" rel="noopener noreferrer">Buy Tokens</a></li> */}
            <li class="btn-alt-2 center-center relative -top-3 mt-4 font-bold">
              <a
                href="../ga/buynow.html"
                id="buyNow"
                class="buyNow text-zinc-200"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Tokens
              </a>
            </li>
          </ul>
        </div>
        {/* <nav id="nav" class="mt-3 flex sm:mt-5 md:hidden">
          <div class="flex h-6 w-8 flex-wrap content-between">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </nav> */}

        {/* <button class=" ">
          Buy Now
        </button> */}
        <a
          href="../ga/buynow.html"
          id="buyNow"
          class="absolute right-3 top-3.5 rounded-full bg-gradient-to-br from-ashred to-ashpurple px-3 py-1 text-sm uppercase text-white md:hidden"
          target="_blank"
          rel="noopener noreferrer"
        >
          Buy Tokens
        </a>
      </div>
      <Nav />
    </header>
  );
}

export default Header3;
