import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import deck from "../assets/deck.jpg";
import deck1 from "../assets/deck1.jpg";
import deck2 from "../assets/deck2.jpg";

import AOS from "aos";

import GetStartCTA from "../components/GetStartCTA";
// import WhatIsAshtoken from '../components/WhatIsAshtoken';

function Presentation() {
  useEffect(() => {
    AOS.init({
      disable: "phone",
    });
  }, []);

  return (
    <main class="decks xgroup">
      {/* <WhatIsAshtoken /> */}
      <div class="my-12 flex w-full flex-col">
        {/* <div class="glow2 h-0 w-0"></div> */}
        <div class="container mx-auto grid grid-cols-2 gap-8 px-4 py-8 md:py-24 lg:px-12">
          <div class="center-left md:center-left order-1 col-span-2 mb-12 md:col-span-1">
            <div class="mx-auto max-w-screen-sm">
              <h2 class="">Community </h2>
              <p class="">
                The first initiative of the ASH Token community will be to
                create Ash DAO, a decentralized organization built on top of
                Binance Smart Chain, that will serve as the platform for all
                community decisions.
              </p>
              <div class="at-list">
                <p class="">
                  <span>𑁋</span> Community driven
                </p>
                <p class="">
                  <span>𑁋</span> Circular economy
                </p>
                <p class="">
                  <span>𑁋</span> Environmental launchpad
                </p>
              </div>
              <p class="">
                <Link
                  class="btn"
                  to="/pdf/ASH-Phoenix-presentation-deck-210623.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Presentation
                </Link>
              </p>
            </div>
          </div>

          <div class="center-center col-span-2 md:col-span-1 ">
            <div class="grid scale-90 grid-cols-1 md:scale-100">
              {/* <img src={deck} title="How Ash Works" alt="How Ash Works"  class="slides opacity-100 mt-0" /> */}
              <img
                src={deck}
                title="How Ash Works"
                alt="How Ash Works"
                id="slide0"
                class="slides slide0 overflow-hidden rounded-xl shadow-2xl"
                data-aos="fade-up"
                data-aos-delay="1000"
              />
              <img
                src={deck2}
                title="How Ash Works"
                alt="How Ash Works"
                id="slide1"
                class="slides slide1 rounded-xl shadow-2xl"
                data-aos="fade-up"
                data-aos-delay="500"
              />
              <img
                src={deck1}
                title="How Ash Works"
                alt="How Ash Works"
                id="slide2"
                class="slides slide2 rounded-xl shadow-2xl"
                data-aos="fade-up"
                data-aos-delay="0"
              />
            </div>
          </div>
        </div>

        {/* <GetStartCTA class="pt-12"/> */}
      </div>
    </main>
  );
}
export default Presentation;
