import React from "react";
// import img from '/logo512.png';

import ato from "../../assets/team/ato.jpg";
import troy from "../../assets/team/troy.jpg";
// import loretta from "../../assets/team/loretta.jpg";
import jagor from "../../assets/team/jagor.jpg";
import angu from "../../assets/team/angu.jpg";
import milos from "../../assets/team/milos.jpg";
import jovan from "../../assets/team/jovan.jpg";

const team = [
  {
    id: 0,
    firstName: "Ato",
    middleName: "B",
    lastName: "Andoh",
    role: "Founder",
    links: {
      linkedIn_URL: "https://www.linkedin.com/in/ato-andoh-5b5a19126/",
      profileURL: "../assets/team/ato.jpg",
    },
    bioExerpt: "...",
    interesting_list: [
      {
        listName: "...",
        listItems: ["...", "..."],
      },
    ],
    bioHTML: {},
  },
  {
    id: 1,
    firstName: "Tchaikawsky",
    middleName: "",
    lastName: "Samuels",
    role: "Technical Co-founder",
    links: {
      linkedIn_URL: "https://www.linkedin.com/in/tchaikawsky-samuels/",
      profileURL: "../assets/team/troy.jpg",
    },
    bioExerpt: "...",
    interesting_list: [
      {
        listName: "...",
        listItems: ["...", "..."],
      },
    ],
    bioHTML: {},
  },
  {
    id: 1,
    firstname: "Tchaikawsky",
    middlename: "",
    lastname: "Samuels",
    title: "Technical Co-founder",
    linkedIn_url: "https://www.linkedin.com/in/tchaikawsky-samuels/",
  },
  {
    id: 2,
    firstname: "Ato",
    middlename: "B",
    lastname: "Andoh",
    role: "Founder",
    linkedIn_url: "",
  },
  {
    id: 3,
    firstname: "Ato",
    middlename: "B",
    lastname: "Andoh",
    role: "Founder",
    linkedIn_url: "",
  },
  {
    id: 4,
    firstname: "Ato",
    middlename: "B",
    lastname: "Andoh",
    role: "Founder",
    linkedIn_url: "",
  },
  {
    id: 5,
    firstname: "Ato",
    middlename: "B",
    lastname: "Andoh",
    role: "Founder",
    linkedIn_url: "",
  },
  {
    id: 6,
    firstname: "Ato",
    middlename: "B",
    lastname: "Andoh",
    role: "Founder",
    linkedIn_url: "",
  },
];

function MeetTheTeam() {
  return (
    <main class="relative z-40">
      <div id="team" class="container mx-auto pb-16 sm:px-8">
        <div class="center-left col-span-12 sm:col-span-6 lg:col-span-8">
          <div class="mx-auto px-8 py-12">
            <h2>Meet the Team</h2>
          </div>
        </div>
        <div id="team-grid" class="gap-8 sm:grid sm:grid-cols-12 sm:gap-12">
          <div class="team-container ">
            <div>
              <img src={ato} title="ato" alt="ato" class="team-pic" />
            </div>
            <div class="team-name">Ato B. Andoh</div>
            <div class="team-title">Founder</div>
            <div class="team-social">
              <a
                href="https://www.linkedin.com/in/ato-andoh-5b5a19126/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div class="team-container ">
            <div>
              <img src={troy} title="troy" alt="troy" class="team-pic" />
            </div>
            <div class="team-name">Tchaikawsky Samuels</div>
            <div class="team-title">Technical Co-founder</div>
            <div class="team-social">
              <a
                href="https://www.linkedin.com/in/tchaikawsky-samuels/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          {/* <div class="team-container ">
            <div>
              <img
                src={loretta}
                title="loretta"
                alt="loretta"
                class="team-pic"
              />
            </div>
            <div class="team-name">Loretta Joseph</div>
            <div class="team-title">
              Digital Asset Regulation and Blockchain Technology Advisor
            </div>
            <div class="team-social">
              <a
                href="https://www.linkedin.com/in/loretta-joseph-853a5b142/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div> */}

          <div class="team-container ">
            <div>
              <img src={jagor} title="jagor" alt="jagor" class="team-pic" />
            </div>
            <div class="team-name">Jagoron Mukherjee</div>
            <div class="team-title">
              Power and Utility Industry, Business Strategy Advisor
            </div>
            <div class="team-social">
              <a
                href="https://www.linkedin.com/in/jag800/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div class="team-container ">
            <div>
              <img src={angu} title="angu" alt="angu" class="team-pic" />
            </div>
            <div class="team-name">Anurag Mista</div>
            <div class="team-title">
              Solar Energy, Phoenix Launchpad Development Advisor
            </div>
            <div class="team-social">
              {/* <a href="https://www.ashtoken.io/#" target="_blank" rel="noopener noreferrer"><i class="bi bi-linkedin"></i></a> */}
            </div>
          </div>

          <div class="team-container ">
            <div>
              <img src={milos} title="milos" alt="milos" class="team-pic" />
            </div>
            <div class="team-name">Miloš Milosavljević</div>
            <div class="team-title">
              Whitepaper Editor and Value Stream Designer
            </div>
            <div class="team-social">
              <a
                href="https://www.linkedin.com/in/milosmilosavljevic/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div>

          <div class="team-container ">
            <div>
              <img src={jovan} title="jovan" alt="jovan" class="team-pic" />
            </div>
            <div class="team-name">Jovan Tišma</div>
            <div class="team-title">
              DAO and Tokenomics Architect, Token Sale Advisor
            </div>
            <div class="team-social">
              <a
                href="https://www.linkedin.com/in/jovan-tisma/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i class="bi bi-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
export default MeetTheTeam;
