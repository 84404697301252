import React from "react";

import Patreon from "./Patreon";
import ContractNumber from "./ContractNumber";
import TokenPrice from "./TokenPrice";

function Triple() {
  const cols = "flex-none pb-8 lg:pb-0";

  return (
    <div class="container relative z-40 mx-auto mb-12 flex flex-col justify-between text-center text-gray-400 lg:flex-row lg:items-end lg:px-8">
      <div className={cols}>
        <ContractNumber />
      </div>
      <div className={cols} class="order-first lg:order-none">
        <TokenPrice />
      </div>

      <div className={cols}>
        <Patreon />
      </div>
    </div>
  );
}

export default Triple;
