import React from "react";
import img from "../Triple/patreon.png";

function Patreon() {
  return (
    <div>
      <a
        href="https://patreon.com/ashtoken"
        class="flex items-center justify-center gap-4"
      >
        <img src={img} class="w-12 flex-none" />
        <div class="flex-none text-white">Support us on Patreon</div>
      </a>
    </div>
  );
}

export default Patreon;
