import React, { useEffect } from "react";
import Tokensale from "./Tokensale";
import TokenStructure from "./TokenStructure";
import WhatIsAshtoken from "./WhatIsAshtoken";

function Token() {
  useEffect(() => {
    document.title = "ASH Token | About The Token";
  }, []);

  return (
    <div>
      <WhatIsAshtoken />
      <TokenStructure />
      <Tokensale />
    </div>
  );
}

export default Token;
