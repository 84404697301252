import React from "react";
import { Link } from "react-router-dom";
import img from "../assets/grantapp-bg.png";

function GrantApp() {
  return (
    <main id="privatesale" class="mt-24 flex px-4 md:px-12">
      {/* //  bg-ashred bg-gradient-to-br from-ashember to-ashred */}
      <a
        href="https://ashtokenemail.com/the-ash-dao-and-phoenix-launchpad"
        class="group container relative mx-auto flex flex-col-reverse overflow-hidden rounded-3xl bg-gradient-to-bl from-blue-600 to-ashblue-ui bg-right-bottom bg-no-repeat p-6
     shadow-2xl transition-[background-position] duration-1000 hover:bg-right-top md:p-12 lg:grid-cols-2 lg:flex-row
  
   "
      >
        <div class="center-center col-span-1">
          <div class=" p-4 lg:p-12">
            <h2 class="text-5xl font-bold">The Ash DAO Grant Application </h2>
            {/* <p class="text-3xl font-bold text-[#62ecca]">April 17th - May 7th</p> */}
            <p class="max-w-none font-sans text-white">
              Ash DAO Grant has developed a step-by-step process that allows you
              to share what makes your project unique and determine how it
              aligns with the vision of the Ash DAO community to fund the next
              generation of companies supporting a circular economy.
            </p>

            <p>
              <div class="btn w-fit"> Start Application </div>
            </p>
          </div>
        </div>
        <div class="center-center relative order-1 col-span-1 mb-8 flex items-center rounded-lg transition-all duration-300 ease-in-out group-hover:scale-105 lg:mb-0">
          <img src={img} class="h-auto w-full rounded-xl shadow-2xl" />
        </div>
      </a>
    </main>
  );
}
export default GrantApp;
