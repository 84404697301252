// import React, { useEffect } from 'react';import React, { useEffect } from 'react';
import React from "react";

// import { init,animate } from '../utils/logo.js';

function AshLogo() {
  //  useEffect(() => {
  //   init();
  //   animate();
  //  }, []);

  return (
    <div
      id="logo-bg"
      class="w-100 absolute left-0 right-0 top-0 mx-auto flex min-h-screen flex-col lg:-top-4"
    >
      {/* <div class="glow1"></div> */}
      {/* <div class="mx-auto grid grid-cols-12 "> */}
      {/* <div class="col-span-6 lg:col-span-5 xl:col-span-5 lg:block"></div> */}
      {/* <div id="logogo" class="col-span-6 md:col-span-5 xl:col-span-6 flex top-center md:center-center opacity-100 lg:opacity-100 relative scale-75 md:scale-100 h-full"></div> */}
      {/* </div> */}
    </div>
  );
}
export default AshLogo;
