import React from "react";
import { Link, NavLink } from "react-router-dom";
import AshLogo2 from "./Ashlogo2";
import CurrentPrice from "./CurrentPrice";
// import { Trans, useTranslation } from "react-i18next";

function Welcome2() {
  // useEffect(() => {
  //     AOS.init({
  //         disable: 'phone'
  //     });
  // }, []);

  // const { t, i18n } = useTranslation();

  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };

  return (
    <div class="relative z-40 w-full px-4 md:px-8">
      {/* <WhatIsAshtoken /> */}
      <div class="container mx-auto grid grid-cols-12">
        <div class="center-center order-last col-span-12 lg:order-first lg:col-span-5">
          <div class="mx-auto text-center md:mx-0 lg:text-left xl:max-w-screen-sm">
            <h1 class="mx-auto inline">
              A Crypto for{" "}
              <span class="inline-block lg:inline">the Planet</span>
              {/* <Trans i18nKey="welcome.title">A Crypto for the Planet</Trans> */}
            </h1>
            <p class="mx-auto mt-2 text-center lg:mx-0 lg:text-left">
              Participate in the funding of sustainable initiatives for
              pollution removal from the global environment and large-scale
              adoption of cleaner energy solutions.
            </p>

            <div class="gridd md:blocke mt-4 hidden grid-cols-12 gap-1 text-lg text-ashblue-light md:gap-0">
              <a
                class="col-span-12 mb-4 
                                    mr-0 block rounded-full border-2 border-none bg-gradient-to-br from-ashred to-ashpurple px-6
                                    py-4 font-bold uppercase drop-shadow-2xl 
                                    hover:from-ashblue-light hover:to-ashblue-light hover:text-ashblue-bgcolor sm:col-span-6 sm:mr-3 sm:inline-block sm:hover:bg-ashblue-light md:hidden"
                href="https://pancakeswap.finance/swap?outputCurrency=0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Tokens
              </a>
              <NavLink id="Navlinkgs" to="/get-started">
                <span class="md:hidden">Get Started </span>
                <i class="bi bi-play-fill hidden md:inline"></i>
              </NavLink>
              <Link
                class="col-span-12 mb-4
                                    mr-0 block rounded-full border-2 border-ashblue-light 
                                    px-6 py-4 
                                    hover:bg-ashblue-light hover:text-ashblue-bgcolor sm:col-span-6 sm:mr-3 sm:inline-block"
                to="/pdf/AshToken-Whitepapers-2023Q4.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Whitepaper
              </Link>
              <Link
                class="col-span-12 mb-4 
                                    mr-0 block rounded-full border-2 border-ashblue-light 
                                    px-6 py-4 
                                    hover:bg-ashblue-light hover:text-ashblue-bgcolor sm:col-span-6 sm:mr-3 sm:inline-block"
                to="/pdf/ASH-Phoenix-presentation-deck-210623.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Presentation
              </Link>
            </div>
            {/* <div class="mt-3 overflow-x-hidden text-ashblue-light">
              <div class="font-bold">Binance Smart Chain Contract</div>
              <div class="overflow-ellipsis text-xs sm:text-sm md:text-base">
                0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9
              </div>
            </div> */}
          </div>
        </div>
        <div class="col-span-0 lg:col-span-3 lg:hidden"></div>
        <div class="center-center col-span-12  lg:col-span-7 lg:block">
          <AshLogo2 />
          {/* <CurrentPrice /> */}
        </div>
      </div>
    </div>
  );
}
export default Welcome2;
