import React from "react";
import img from "../embers.png";

import Donut from "../components/Donut";

function TokenStructure() {
  // const TokenStructure = () =>{
  return (
    <main>
      <div class="container mx-auto grid grid-cols-2 gap-8 px-4 py-16 lg:px-12">
        {/* <div class="col-span-2 md:col-span-1 lg:col-span-2 center-left relative z-50">
     <div>
      <h2 class="md:text-7xl">
       Token <br/> Structure
      </h2>
     </div>
    </div> */}

        <div
          id="donut"
          class="md:center-center relative z-10 col-span-2 w-full sm:col-span-1"
        >
          {/* <div>
      <img src={img} title="chart" alt="chart" class="hello" />
     </div> */}
          {/* <div class="col-span-1 lg:col-span-2"> */}
          {/* <div width="w-full flex flex-col"> */}
          {/* <div class="w-full">
        hello
       </div> */}
          {/* <canvas  class="w-full h-auto"></canvas> */}
          {/* </div> */}
          <Donut id="token-structure" class="h-full w-full" />
          {/* </div> */}
        </div>
        <div class="center-center relative z-40 col-span-1 lg:col-span-1  lg:hidden">
          <h2 class="sm:text-5xl md:text-7xl">
            Token <br /> Structure
          </h2>
        </div>

        <div class="center-center relative z-40 col-span-2 lg:col-span-1">
          <h2 class="hidden sm:text-5xl md:text-7xl lg:block">
            Token <br /> Structure
          </h2>
          <div class="col-span-1 grid w-full grid-cols-1 justify-start lg:col-span-3">
            {/* Row */}
            <div class="span-cols-1 mb-4 grid w-full grid-cols-3 gap-y-1 rounded-2xl bg-ashblue-ui px-1 py-1 shadow-2xl md:grid-cols-4">
              <div class="col-span-full   items-center gap-1 p-2 px-3 py-1 text-center md:col-span-1 md:flex md:text-left">
                <div class="hidden gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Allocation
                </div>
                <div class=" underline-red py-1 font-bold uppercase">
                  Foundation
                </div>
              </div>
              <div class="flex flex-col items-center px-3 py-1">
                <div class=" gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Tokens
                </div>
                <div class="px-3 py-1 text-center sm:text-left">
                  <span class="hidden xl:block">5,500,000,000,000</span>
                  <span class="xl:hidden">5.5t</span>
                </div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Shares</div>
                <div class="px-3 py-1 sm:block">55%</div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Valuation</div>
                <div class=" px-3 py-1">
                  <span class="hidden xl:block">$16,500,000,000</span>
                  <span class="xl:hidden">$16.5b</span>
                </div>
              </div>
            </div>
            {/* End of Row */}

            {/* Row */}
            <div class="span-cols-1 mb-4 grid w-full grid-cols-3 gap-y-1 rounded-2xl bg-ashblue-ui px-1 py-1 shadow-2xl md:grid-cols-4">
              <div class="col-span-full   items-center gap-1 p-2 px-3 py-1 text-center md:col-span-1 md:flex md:text-left">
                <div class="hidden gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Allocation
                </div>
                <div class=" underline-ember py-1 font-bold uppercase">
                  Fundraise
                </div>
              </div>
              <div class="flex flex-col items-center px-3 py-1">
                <div class=" gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Tokens
                </div>
                <div class="px-3 py-1 text-center sm:text-left">
                  <span class="hidden xl:block">3,000,000,000,000</span>
                  <span class="xl:hidden">3t</span>
                </div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Shares</div>
                <div class="px-3 py-1 sm:block">30%</div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Valuation</div>
                <div class=" px-3 py-1">
                  <span class="hidden xl:block">9,000,000,000</span>
                  <span class="xl:hidden">$9b</span>
                </div>
              </div>
            </div>
            {/* End of Row */}

            {/* Row */}
            <div class="span-cols-1 mb-4 grid w-full grid-cols-3 gap-y-1 rounded-2xl bg-ashblue-ui px-1 py-1 shadow-2xl md:grid-cols-4">
              <div class="col-span-full   items-center gap-1 p-2 px-3 py-1 text-center md:col-span-1 md:flex md:text-left">
                <div class="hidden gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Allocation
                </div>
                <div class=" underline-blue py-1 font-bold uppercase">Team</div>
              </div>
              <div class="flex flex-col items-center px-3 py-1">
                <div class=" gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Tokens
                </div>
                <div class="px-3 py-1 text-center sm:text-left">
                  <span class="hidden xl:block">1,000,000,000,000</span>
                  <span class="xl:hidden">1t</span>
                </div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Shares</div>
                <div class="px-3 py-1 sm:block">10%</div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Valuation</div>
                <div class=" px-3 py-1">
                  <span class="hidden xl:block">$3,000,000,000</span>
                  <span class="xl:hidden">$3b</span>
                </div>
              </div>
            </div>
            {/* End of Row */}

            {/* Row */}
            <div class="span-cols-1 mb-4 grid w-full grid-cols-3 gap-y-1 rounded-2xl bg-ashblue-ui px-1 py-1 shadow-2xl md:grid-cols-4">
              <div class="col-span-full   items-center gap-1 p-2 px-3 py-1 text-center md:col-span-1 md:flex md:text-left">
                <div class="hidden gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Allocation
                </div>
                <div class=" underline-blue-lt py-1 font-bold uppercase">
                  Liquidity
                </div>
              </div>
              <div class="flex flex-col items-center px-3 py-1">
                <div class=" gap-2 rounded-2xl px-3 py-1 text-gray-400">
                  Tokens
                </div>
                <div class="px-3 py-1 text-center sm:text-left">
                  <span class="hidden xl:block">500,000,000,000</span>
                  <span class="xl:hidden">5b</span>
                </div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Shares</div>
                <div class="px-3 py-1 sm:block">5%</div>
              </div>
              <div class=" flex flex-col items-center px-3 py-1">
                <div class=" px-3 py-1 text-gray-400">Valuation</div>
                <div class=" px-3 py-1">
                  <span class="hidden xl:block">$1,500,000,000</span>
                  <span class="xl:hidden">$1.5b</span>
                </div>
              </div>
            </div>
            {/* End of Row */}
          </div>
        </div>
      </div>
    </main>
  );
}
export default TokenStructure;
