import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import img from "../../assets/ashtoken-logo-full-color.svg";
import Nav from "../Nav";

function Header() {
  useEffect(() => {
    window.onscroll = function () {
      scrolll();
    };

    var header = document.getElementById("header");
    var headerscroll = header.offsetTop;

    function scrolll() {
      if (window.pageYOffset > headerscroll) {
        header.classList.add("headerscroll");
      } else {
        header.classList.remove("headerscroll");
      }
    }

    var nav = document.getElementById("nav");

    nav.addEventListener("click", function () {
      document.getElementById("nav").classList.toggle("active");
      document.getElementById("header").classList.toggle("active");
      document.getElementById("mobileNav").classList.toggle("active");

      var resized = window.addEventListener("resize", resizeNav);

      function resizeNav() {
        var screenwidth = window.innerWidth;

        if (screenwidth > 768) {
          document.getElementById("nav").classList.remove("active");
          document.getElementById("header").classList.remove("active");
          document.getElementById("mobileNav").classList.remove("active");
        }
      }
    });

    var amobilelink = document
      .getElementById("mobileNav")
      .getElementsByTagName("a");
    var i;
    for (i = 0; i < amobilelink.length; i++) {
      amobilelink[i].addEventListener("click", function () {
        document.getElementById("nav").classList.remove("active");
        document.getElementById("header").classList.remove("active");
        document.getElementById("mobileNav").classList.remove("active");
      });
    }
  }, []);

  return (
    <header id="header" class="sticky z-50 h-screen px-4 sm:px-12">
      <div class="mx-auto flex justify-between py-2 md:py-4">
        <Link id="logo" class="" to="/">
          <img src={img} alt="ASHtoken Logo" class="" />
        </Link>
        <div class="mt-1.5 hidden md:flex">
          <ul class="flex">
            <li class="navli">
              <NavLink to="/get-started">Get Started</NavLink>
            </li>
            {/* <li class="navli"><a href="https://dev.d340ancianf15k.amplifyapp.com" target="_blank" rel="noopener noreferrer">Referral</a></li> */}
            {/* <li class="navli"><a href="/#">Articles</a></li> */}
            <li class="mr-4"></li>
            {/* <li class="btn-alt-2 center-center font-bold mt-4 relative -top-3"><a href="https://pancakeswap.finance/swap?outputCurrency=0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9" id="buyNow" class="buyNow" target="_blank" rel="noopener noreferrer">Buy Tokens</a></li> */}
            <li class="btn-alt-2 center-center relative -top-3 mt-4 font-bold">
              <a
                href="../ga/buynow.html"
                id="buyNow"
                class="buyNow"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Tokens
              </a>
            </li>
          </ul>
        </div>
        <nav id="nav" class="mt-3 flex md:hidden">
          <div class="flex h-6 w-8 flex-wrap content-between">
            <div class="bar"></div>
            <div class="bar"></div>
            <div class="bar"></div>
          </div>
        </nav>
      </div>
      <Nav />
    </header>
  );
}

export default Header;
