import React from "react";

function ContractNumber() {
  return (
    <div>
      <div class="font-bold uppercase text-white">
        Binance Smart Chain Contract
      </div>
      <div class="text-sm">0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9</div>
    </div>
  );
}

export default ContractNumber;
