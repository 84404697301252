import React from "react";
// import img from "../assets/video.png";
import { Link } from "react-router-dom";

function PrivateSale() {
  return (
    <main id="privatesale" class="flex px-4 md:px-12 relative z-auto">
      {/* //  bg-ashred bg-gradient-to-br from-ashember to-ashred */}
      <a
        href="https://the.ashtoken.io/privatesale"
        class="group container relative mx-auto grid grid-cols-1 overflow-hidden rounded-3xl bg-ashblue-dark  p-6 shadow-2xl 
     duration-1000 md:p-12 lg:grid-cols-2 bg-casper bg-cover hover:bg-[110%]
  
   "
      >
        <div class="center-center col-span-1">
          <div class=" p-4 lg:p-12">
            <h2 class="text-5xl font-bold text-white md:text-7xl">
              Private Sale{" "}
            </h2>
            <p class="text-3xl font-bold text-ashblue-formbutton drop-shadow">September 1st - October 31st</p>
            <p class="font-sans text-white">
              Purchase the Ash Token at a discount and participate in the
              ecosystem’s global cause to fund high impact innovations and rid
              the world of pollution while creating jobs and fostering economic
              development across the world.
            </p>

            <p>
              <div class="group/privatebtn mb-2 inline-block rounded-3xl px-5 py-3 font-bold uppercase text-ashblue-dark hover:bg-[#f1d835 bg-white/90 hover:bg-white transition-all drop-shadow-lg">
                {" "}
                Register Now <span class="inline-block relative transition-all right-0 group-hover/privatebtn:-right-0.5  group-hover:rotate-45">↗︎</span>{" "}
              </div>
            </p>
          </div>
        </div>
        <div class="center-center aspect-h-9 aspect-w-16 relative col-span-1 rounded-lg bg-coin-crop bg-contain bg-center bg-no-repeat transition-all duration-300 ease-in-out group-hover:scale-105 drop-shadow-lg hover:drop-shadow-2xl">
          <div class="flex flex-col"></div>

          {/* <div class="h-20 w-20 rounded-full flex center-center btn shadow-lg">
       <i class="fa fa-play text-4xl text-white pl-1 max"></i>
      </div> */}
        </div>
      </a>
    </main>
  );
}
export default PrivateSale;
