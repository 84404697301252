import React from "react";
import img from "../assets/video.png";
import { Link } from "react-router-dom";

function Welcome() {
  return (
    <main id="welcome" class="flex px-4 md:px-12">
      {/* //  bg-ashred bg-gradient-to-br from-ashember to-ashred */}
      <Link
        to="/get-started"
        class="group container relative mx-auto grid grid-cols-1 overflow-hidden rounded-3xl bg-gradient-to-br from-ashpurple to-ashblue-btn 
     p-6 shadow-2xl md:p-12 lg:grid-cols-2
  
   "
      >
        <div class="center-center col-span-1">
          <div class=" p-4 lg:p-12">
            <h2 class="text-5xl font-bold md:text-7xl">Let's Get Started</h2>
            <p class="font-sans font-bold text-white">
              See our video and written instructions on how to get AshToken into
              your wallet.
            </p>

            <p>
              {/* <Link to="/get-started" class="btn-blue">Let's Go!</Link> */}
            </p>
          </div>
        </div>
        <div class="center-center aspect-h-9 aspect-w-16 relative col-span-1 rounded-lg bg-coinage bg-cover shadow-lg transition-all duration-300 ease-in-out group-hover:scale-105">
          {/* <div
            class="center-center border-0.5 mx-auto my-auto h-14 w-14 rounded-full border-ashpurple bg-gradient-to-br from-ashember
     to-ashred bg-pos-0 shadow-2xl hover:bg-gradient-to-br hover:from-ashred
     hover:to-ashpurple hover:bg-pos-100"
          >
            <i class="bi bi-play-fill pl-1 text-2xl text-ashblue-light"></i>
          </div> */}
          <div class="flex flex-col"></div>

          {/* <div class="h-20 w-20 rounded-full flex center-center btn shadow-lg">
       <i class="fa fa-play text-4xl text-white pl-1 max"></i>
      </div> */}
        </div>
      </Link>
    </main>
  );
}
export default Welcome;
