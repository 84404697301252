import React from "react";
import { Link, NavLink } from "react-router-dom";
import Footer from "./footer/Footer.js";

function Header() {
  return (
    <div id="mobileNav" class="overflow-scroll">
      <Footer />
    </div>
  );
}
export default Header;

// {/* <div id="socialmedia-nav" class="flex items-center justify-center py-8">
// <a
//   href="https://twitter.com/ashtoken"
//   target="_blank"
//   rel="noopener noreferrer"
//   class=""
// >
//   <i class="bi bi-twitter "></i>
// </a>
// <a
//   href="https://www.linkedin.com/company/74475980/admin/"
//   target="_blank"
//   rel="noopener noreferrer"
//   class=""
// >
//   <i class="bi bi-linkedin"></i>
// </a>
// <a
//   href="https://medium.com/@ash_token"
//   target="_blank"
//   rel="noopener noreferrer"
//   class=""
// >
//   <i class="bi bi-medium"></i>
// </a>
// <a
//   href="https://t.me/ashtoken"
//   target="_blank"
//   rel="noopener noreferrer"
//   class=""
// >
//   <i class="bi bi-telegram"></i>
// </a>
// </div> */}
