import React from "react";

import ecogreensolution from "./img/ecogreensolutions.jpg";
import gcsfibers from "./img/gcsfibers.png";
import metafiber from "./img/metafiber.jpg";
import sinanenergy from "./img/sinanenergy.png";
import transvolt from "./img/transvolt.jpeg";

function SponsorsSection() {
  const sponsors = [
    {
      imgSrc: ecogreensolution,
      link: "https://www.ecogreensolutions.io",
      name: "Eco Green Solutions",
    },
    {
      imgSrc: gcsfibers,
      link: "https://www.gcsfibers.com",
      name: "GCS Fibers",
    },
    {
      imgSrc: metafiber,
      link: "https://www.metafiber.io",
      name: "MetaFiber",
    },
    {
      imgSrc: sinanenergy,
      link: "https://www.sinanenergy.com",
      name: "Sinan Energy",
    },
    {
      imgSrc: transvolt,
      link: "https://transvoltenergies.com",
      name: "Transvolt",
    },
  ];

  // link: "https://www.transvoltenenergies.com"

  return (
    <div className="mx-4">
      <div className="container relative z-40 mx-auto mb-12 grid grid-cols-1 gap-4 rounded-2xl bg-white px-12 py-8 sm:grid-cols-2 md:grid-cols-3">
        <h2 class="col-span-1 mt-6 text-center text-ashblue-btn sm:col-span-2 md:col-span-3">
          Current Ash Token Sponsored Companies
        </h2>
        {sponsors.map((sponsor, index) => (
          <div key={index} className="flex items-center p-4">
            <a
              href={sponsor.link}
              target="_blank"
              rel="noopener noreferrer"
              alt={sponsor.name}
            >
              <img
                src={sponsor.imgSrc}
                alt={sponsor.name}
                className="mx-auto scale-[0.65]"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
}

export default SponsorsSection;
