import React, { useEffect } from "react";
// import './App.css';

function OurStory() {
  useEffect(() => {
    document.title = "ASH Token | Our Story";
  }, []);

  return (
    <div id="ourstory">
      <div class="container mx-auto my-4 grid grid-cols-12 overflow-hidden rounded-3xl px-4 pb-16 ">
        <div class="col-span-12">
          <h1 class="mb-10 text-center tracking-wide">Our Story</h1>
        </div>
        <div class="col-span-12">
          <div class="mx-auto w-full max-w-3xl text-slate-300 md:text-2xl md:leading-8">
            <div class="mb-6">
              Entrepreneurs with innovative ideas usually need one major thing
              to make their business idea a reality...Capital! For many, it’s as
              simple as taking out a loan, asking friends and family or using
              their personal savings. For innovators who especially want to make
              a positive and lasting environmental impact with their ideas, they
              have often found the process of raising capital to be a
              challenging endeavor.
            </div>
            <div class="mb-6">
              Having initially invested in an emerging technology that recycled
              toxic coal ash into six types of mineral fibers, my co-founder and
              I started GCS Fibers in order to commercialize the technology.
              Coal ash is a byproduct from coal-fired power plants that is then
              disposed off in landfills and ash ponds. Eventually, the toxins
              and heavy metals in the coal ash leach into ground waters across
              communities. Our company’s initiative to repurpose coal ash will
              generate substantial and meaningful jobs, reduce the public health
              costs associated with the ills of coal ash, and provide
              high-quality mineral fibers some of which, can even reduce energy
              consumption and greenhouse gas emissions from buildings.
            </div>
            <div class="mb-6">
              Throughout our endeavor, the biggest challenge that we faced was
              that of raising capital. From my prior professional experience in
              small business development at the U.S. Department of Defense, the
              process of providing grants or even outright funding for
              environmental initiatives was much more time consuming and
              frustrating than other non-environmental initiatives. The same
              problems affect many start-ups or emerging green businesses as
              they try to source funding for their innovations. In my opinion,
              this issue stems from traditional project financing institutions
              not being willing or able to consider the long-term impact of the
              innovation on our planets ecology as part of their bottom-line or
              return on investment. There’s simply had to be a better way to get
              funding for these high global impact, job-creating and high profit
              generating projects.
            </div>
            <div class="mb-6">
              The Ash Environmental DOA was created because we see the value in
              supporting environmentally friendly innovation and initiatives. We
              know first-hand, the frustrations involved with the raising of
              capital for the commercialization of great ideas. So, we built a
              platform around cryptocurrency’s ability to disrupt that status
              quo for funding environmental businesses around the world.
            </div>
            <div class="mb-6">
              When you contribute to, and support our platform, you will be
              making a positive change for you, our grant recipients, and the
              planet.
            </div>
            <div class="my-12">
              Ato Andoh, Founder & CEO, The Ash Environmental DAO
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurStory;
