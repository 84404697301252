import React from "react";
import { Doughnut } from "react-chartjs-2";

import { Chart, ArcElement } from "chart.js";
Chart.register(ArcElement);

const Donut = () => {
  return (
    <div class="w-full">
      <Doughnut
        id="token-structure"
        data={{
          labels: ["Foundation", "Fundraise", "Team", "Liquidity Pool"],
          datasets: [
            {
              backgroundColor: ["#BD1622", "#F3994F", "#629FD9", "#c2d9e8"],
              data: [55, 30, 10, 5],
              borderColor: "rgba(0,0,0,0)",
            },
          ],
        }}
        options={{
          title: {
            display: false,
            text: "Token Structure",
          },
          tooltips: {
            enabled: false,
          },
          aspectRatio: 1,
          legend: {
            display: false,
          },
          plugins: {
            legend: {
              display: false,
            },
          },
        }}
        height={600}
        width={600}
      />
    </div>
  );
};

// var xValues = ["Foundation", "Fundraise", "Team", "Liquidity Pool"];
// var yValues = [55, 30, 10, 5];
// var barColors = [
//   "#BD1622",
//   "#F3994F",
//   "#629FD9",
//   "#c2d9e8"
// ];

// new Chart("token-structure", {
//   type: "doughnut",
//   data: {
//    labels: ["Foundation", "Fundraise", "Team", "Liquidity Pool"],
//     datasets: [{
//      backgroundColor: ["#BD1622","#F3994F","#629FD9","#c2d9e8"],
//      data: [55, 30, 10, 5],
//       borderColor: "rgba(0,0,0,0)"
//     }]
//   },
//   options: {
//     title: {
//       display: false,
//       text: "Token Structure",
//     },
//     tooltips: {
//       enabled: false
//     },
//     aspectRatio: 1,
//     legend: {
//       display: false
//     },
//     plugins: {
//       legend: {
//         display: false
//       }
//     }
//   }

// });

export default Donut;
