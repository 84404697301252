import React from "react";
import img from "../embers.png";
import Roadmap from "../components/Roadmap";

function Tokensale() {
  return (
    <main
      id="tokenSales"
      class="flex w-full flex-col bg-rocket bg-contain bg-no-repeat bg-origin-content"
    >
      <div
        class="container mx-auto grid grid-cols-3 gap-8 px-4 lg:px-12 "
        data-aos="fade-up"
        data-aos-mirror="true"
      >
        <div class="center-left col-span-3">
          <div class="pb-8">
            <h2>
              <i class="fa fa-check-circle theme-text"></i> Token Sale Stages
            </h2>
            <p class="" style={{ textShadow: "2px 1px 0px #000000" }}>
              Took us 2.5 months from our first private sale to public sale, and
              we're just getting started.
            </p>
          </div>
        </div>
        {/* 1 */}
        <div
          class="center-center col-span-3 md:col-span-1"
          data-aos="fade-up"
          data-aos-delay="1000"
        >
          <div class="center-center w-full rounded-2xl bg-ashblue-ui py-4 shadow-2xl md:py-8">
            <div class="mb-2 pb-1 text-2xl">Private Sale R1</div>
            <div class="text-gray-400">May 24 - June 14, 2021</div>
            <div class="mt-2 uppercase text-gray-400">
              Completed <i class="fa fa-check"></i>
            </div>
          </div>
        </div>
        {/* 2 */}
        <div
          class="center-center col-span-3 md:col-span-1"
          data-aos="fade-up"
          data-aos-delay="1250"
        >
          <div class="center-center  w-full rounded-2xl  bg-ashblue-ui py-4 shadow-2xl md:py-8">
            <div class="mb-2 pb-1 text-2xl">Private Sale R2</div>
            <div class="text-gray-400">June 21 - July 14, 2021</div>
            <div class="mt-2 uppercase text-gray-400">
              Completed <i class="fa fa-check"></i>
            </div>
          </div>
        </div>
        {/* 3 */}
        <div
          class="center-center col-span-3 md:col-span-1"
          data-aos="fade-up"
          data-aos-delay="1500"
        >
          <div class="center-center  w-full rounded-2xl  bg-ashblue-ui py-4 shadow-2xl md:py-8">
            <div class="mb-2 pb-1 text-2xl">Public Sale</div>
            <div class="text-gray-400">August 9, 2021</div>
            <div class="mt-2 uppercase text-gray-400">
              Pancake Swap <i class="fa fa-check"></i>
            </div>
          </div>
        </div>
      </div>
      {/* <Roadmap/> */}
    </main>
  );
}
export default Tokensale;
