import * as THREE from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";

let camera, scene, renderer;

let mouseX = 0,
  mouseY = 0;

let windowHalfX = window.innerWidth / 2;
let windowHalfY = window.innerHeight / 2;

// init();
// animate();

export function init() {
  // Instantiate a loader
  // const loader = new GLTFLoader();

  const page = document.getElementById("logogo");

  const container = document.createElement("div");
  container.classList.add("ashlogo");
  // document.body.getElementById("page").appendChild(container);
  page.insertBefore(container, page.firstChild);

  camera = new THREE.PerspectiveCamera(
    80,
    window.innerWidth / window.innerWidth,
    1,
    2000
  );
  camera.position.z = 76;
  // camera.position.x = 3000;
  // camera.position.y = 2000;

  scene = new THREE.Scene();

  const ambient = new THREE.AmbientLight(0xffffff, 1);
  scene.add(ambient);

  const directionalLight = new THREE.PointLight(0xffffff, 1, 20000);
  directionalLight.position.set(0, 3, 50);
  scene.add(directionalLight);

  const directionalLight1 = new THREE.PointLight(0xfffff, 1, 1000);
  directionalLight1.position.set(1, 0, 50);
  //  scene.add(directionalLight1);

  new RGBELoader()
    .setPath("textures/")
    .load("venice_sunset_1k.hdr", function (texture) {
      texture.mapping = THREE.EquirectangularReflectionMapping;

      //    scene.background = texture;
      scene.background = null;
      //    scene.environment = texture;

      render();

      // model

      // use of RoughnessMipmapper is optional
      // const roughnessMipmapper = new RoughnessMipmapper(renderer);

      const loader = new GLTFLoader().setPath("models/");
      loader.load("token.gltf", function (gltf) {
        gltf.scene.traverse(function (child) {
          if (child.isMesh) {
            // roughnessMipmapper.generateMipmaps(child.material);
          }
        });

        // gltf.scene.position.x = 20 / (windowHalfX / (1000 / windowHalfY));
        // gltf.scene.position.x = 15 / (windowHalfY / (100 / windowHalfY));
        // gltf.scene.position.y = 1 / (windowHalfY / 2);
        // gltf.scene.position.z = -.02;
        scene.add(gltf.scene);

        // roughnessMipmapper.dispose();

        render();
      });
    });

  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
  renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setSize(window.innerHeight, window.innerHeight);
  renderer.setClearColor(0x000000, 0);
  container.appendChild(renderer.domElement);

  // window.addEventListener('resize', onWindowResize);

  document.addEventListener("mousemove", onDocumentMouseMove);
}

function onWindowResize() {
  windowHalfX = window.innerWidth / -40;
  windowHalfY = window.innerHeight / -40;

  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();

  renderer.setSize(window.innerWidth, window.innerHeight);
}

function onDocumentMouseMove(event) {
  mouseX = (event.clientX - windowHalfX) / -30;
  mouseY = (event.clientY - windowHalfY) / -30;
}

//

export function animate() {
  requestAnimationFrame(animate);
  render();
}

function render() {
  camera.position.x += (mouseX - camera.position.x) * 0.1;
  camera.position.y += (-mouseY - camera.position.y) * 0.1;

  camera.lookAt(scene.position);

  renderer.render(scene, camera);
}
