import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/ashtoken-logo-full-color.svg";
import "./Footer.css";

// import { useTranslation, withTranslation, Trans } from "react-i18next";
// import i18n from "../../i18n";

// function Footer({ t, i18n }) {

function Footer() {
  // const { t, i18n } = useTranslation();
  // i18n.changeLanguage('en');
  // const changeLanguage = (language) => {
  //   i18n.changeLanguage(language);
  // };

  const hideLogoText = {
    width: "64px",
    height: "64px",
    overflow: "hidden",
    backgroundImage: `url(${logo})`,
    backgroundSize: "cover",
  };

  return (
    <footer
      class="w-100 relative z-40 overflow-hidden rounded-b-2xl border-b border-l border-r border-gray-800 bg-black/20 px-4  pb-4
    pt-4 shadow-2xl sm:px-12 sm:pb-8 md:mt-16 md:pt-8 lg:border-none lg:bg-transparent"
      style={{ WebkitBackdropFilter: "blur(6px)" }}
    >
      <div class="container mx-auto grid grid-cols-3 md:grid-cols-5">
        <div class="order-last col-span-3 grid grid-cols-1 content-between md:order-first md:col-span-2">
          <div class="hidden md:block">
            <div style={hideLogoText} class=""></div>

            {/* <Trans i18nKey="welcome.title">
              To get started, edit <code>src/App.js</code> and save to reload.
            </Trans> */}
            {/* <div class="mt-4 mb-6 text-xl text-white">{t("welcome.title")}</div> */}
          </div>

          <div class="mb-0">
            <ul class="footer-social-media">
              <li>
                <a href="https://twitter.com/ashtoken">
                  <i class="bi bi-twitter"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/74475980/">
                  <i class="bi bi-linkedin"></i>
                </a>
              </li>
              <li>
                <a href="https://medium.com/@ash_token">
                  <i class="bi bi-medium"></i>
                </a>
              </li>
              <li>
                <a href="https://t.me/ashtokennews">
                  <i class="bi bi-telegram"></i>
                </a>
              </li>
              <li>
                <a href="http://patreon.com/ashtoken" title="patreon">
                  <div class="block h-8 w-8 bg-patreon bg-contain hover:fill-teal-400"></div>
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div class="footer-link-columns">
          <ul>
            <h6>Main menu</h6>
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <a
                href="https://pancakeswap.finance/swap?outputCurrency=0xE3c1bDeEc4dB91CD90c336776332FAe2E00fdDd9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Buy Tokens
              </a>
            </li>
            <li>
              <a
                href="https://ashtokenemail.com/referral"
                target="_blank"
                rel="noopener noreferrer"
              >
                Referral
              </a>
            </li>
            <li>
              <a
                href="https://register.ashtoken.io/the-ash-dao-and-phoenix-launchpad-phase1"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ash DOA Fund
              </a>
            </li>
            <li>
              <a
                href="https://docs.ashtoken.io"
                target="_blank"
                rel="noopener noreferrer"
              >
                Goverance
              </a>
            </li>
          </ul>
        </div>

        <div class="footer-link-columns">
          <ul>
            <h6>About ASH Token</h6>
            <li>
              <Link to="/get-started">Get Started</Link>
            </li>
            <li>
              <Link to="/ourstory">Our Story</Link>
            </li>
            <li>
              <Link
                to="/pdf/AshToken-Whitepapers-2023Q4.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Whitepapers
              </Link>
            </li>
            <li>
              <Link
                to="/pdf/ASH-Phoenix-presentation-deck-210623.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Presentation
              </Link>
            </li>
            <li>
              <Link to="/blog">Blog</Link>
            </li>
          </ul>
        </div>

        <div class="footer-link-columns">
          <ul>
            <h6>Legal Policies</h6>
            <li>
              <Link to="/legal">Legal</Link>
            </li>
            <li>
              <a
                href="https://app.termly.io/document/terms-of-use-for-website/ab224da1-74e6-4c62-96b5-a5b767f960c9"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
            </li>
            <li>
              <a
                href="https://app.termly.io/document/privacy-policy/e896bc32-1a32-4b23-bff3-eb588e8a8b14"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <Link to="/team">Team</Link>
            </li>
          </ul>
        </div>

        <div class="col-span-5 mt-12 h-0 border-zinc-200 md:border"></div>
      </div>
    </footer>
  );
}

// export default withTranslation()(Footer);

export default Footer;
