import React from "react";
import blogs from "./blogs.json";
import { Link } from "react-router-dom";

function BlogSection() {
  return (
    <main class="relative px-8 md:mb-16 md:px-12">
      <div class="container mx-auto py-4 sm:px-8 lg:px-0">
        <div class="center-left col-span-12 sm:col-span-6 lg:col-span-8">
          <div class="mx-auto w-full px-4 pb-2 pt-12">
            <h2 class="w-full">Ash Token Blog</h2>
          </div>
        </div>
        <div
          id="blog-grid"
          class="gap-8 px-4 sm:grid sm:grid-cols-1 sm:gap-4 lg:grid-cols-3"
        >
          {blogs.slice(0, 3).map((blog) => (
            <div class="col-span-1 mx-auto overflow-hidden border-t border-slate-500 pb-8 pt-4 md:pb-12 md:pt-8 lg:border-transparent">
              <a
                href={blog.link}
                title={blog.title}
                alt={blog.title}
                target="_blank"
                rel="noopener noreferrer"
                class="ease gap-x- group grid grid-cols-12 text-white transition duration-1000"
              >
                <div class="col-span-12 text-slate-500 lg:text-transparent">
                  {blog.pubDate}
                </div>
                <div class="col-span-8 grid content-between lg:col-span-12">
                  <div class="min-h-[2.75em] font-bold decoration-ashblue/50 underline-offset-4 line-clamp-2 sm:text-xl md:text-2xl">
                    {blog.title}{" "}
                    <i class="bi bi-box-arrow-up-right relative bottom-0.5 text-sm text-transparent group-hover:text-ashblue"></i>
                  </div>
                  <div class="hidden pt-2 text-slate-500 line-clamp-4 group-hover:text-slate-200">
                    {blog.desc}
                  </div>
                </div>
                <div class="col-span-4 lg:order-first  lg:col-span-12">
                  <img
                    src={blog.img}
                    class="h-auto w-full overflow-hidden rounded border border-ashblue pt-1.5 lg:pt-0"
                  />
                </div>
              </a>
            </div>
          ))}
        </div>
        <div class="mx-auto text-right lg:text-center">
          <Link class="text-ashblue hover:text-ashblue-light" to="/blog">
            <span class="underline-offset-4 hover:underline ">
              Read More Blog Posts
            </span>{" "}
            <i class="bi bi-arrow-right"></i>
          </Link>
        </div>
      </div>
    </main>
  );
}
export default BlogSection;
