import React, { useEffect } from "react";
import blogs from "./blogs.json";

function Blog() {
  useEffect(() => {
    document.title = "ASH Token | Blog";
  }, []);

  return (
    <div id="page" class="overflow-x-hidden px-4 md:px-8">
      <div class="mx-auto my-4 md:my-8 md:max-w-3xl">
        <h2>Blog</h2>
        <div class="text-lg text-slate-500">
          Follow us over at{" "}
          <a
            href="https://medium.com/@ash_token"
            target="_blank"
            rel="noopener noreferrer"
            class="group pl-1 text-slate-300 hover:text-ashblue"
            title="Medium"
          >
            <i class="bi bi-medium relative -bottom-1.5 text-3xl"></i> Medium{" "}
            <i class="bi bi-box-arrow-up-right relative bottom-0.5 text-sm text-transparent group-hover:text-ashblue"></i>
          </a>
        </div>
      </div>
      {blogs.map((blog) => (
        <div class="mx-auto border-t border-slate-500 pb-8 pt-4 md:max-w-3xl md:pb-12 md:pt-8">
          <a
            href={blog.link}
            title={blog.title}
            alt={blog.title}
            target="_blank"
            rel="noopener noreferrer"
            class="ease group grid grid-cols-12 gap-x-4 text-white transition duration-1000 md:gap-x-8"
          >
            <div class="col-span-12 text-slate-500">{blog.pubDate}</div>
            <div class="col-span-8 grid content-between">
              <div class="font-bold decoration-ashblue/50 underline-offset-4 sm:text-xl md:text-2xl ">
                {blog.title}{" "}
                <i class="bi bi-box-arrow-up-right relative bottom-0.5 text-sm text-transparent group-hover:text-ashblue"></i>
              </div>
              <div class="hidden text-slate-500 line-clamp-3 group-hover:text-slate-200">
                {blog.desc}...
              </div>
            </div>
            <div class="col-span-4">
              <img
                src={blog.img}
                class="mt-1.5 h-auto w-full rounded-sm border border-ashblue"
                alt={blog.title}
                title={blog.title}
              />
            </div>
          </a>
        </div>
      ))}
    </div>
  );
}

export default Blog;
